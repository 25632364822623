import DataApiProvider from "@/services/providers/DataApiProvider";

const data_api_provider = new DataApiProvider();
data_api_provider.setAuthorizationHeadersFromLocal();

export default {
  state: {
    latest_data: null,
    dialog_data: null,
    text_dialog_data: null,
    text_dialog_type: null,
    is_display_dialog: false,
    is_display_text_dialog: false,
    is_display_dashboard_dialog: false,
  },
  actions: {
    async requestGetLatestData({ commit }, params) {
      commit("SET_FETCHING");
      try {
        const response = await data_api_provider.requestGetLatestData(params);
        commit("SET_DATA", response.latest);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_FETCHED");
    },
    async requestGetDashboardDialogData({ commit }, params) {
      commit("SET_FETCHING");
      try {
        const response = await data_api_provider.requestGetLatestData(params);
        commit("SET_DIALOG_DATA", response.latest);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_FETCHED");
      commit("SET_DASHBOARD_DIALOG_VISIBILITY", true);
    },
    async requestGetDialogData({ commit }, params) {
      commit("CLEAR_DIALOG_DATA");
      commit("SET_FETCHING");
      try {
        const response = await data_api_provider.requestGetLatestData(
          params.dialog_params
        );
        commit("SET_DIALOG_DATA", {
          data: response.latest[0],
          machine: params.machine_info,
        });
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_FETCHED");
      commit("SET_DIALOG_VISIBILITY", true);
    },
  },
  mutations: {
    SET_DATA(state, payload) {
      state.latest_data = payload;
    },
    CLEAR_LATEST_DATA(state) {
      state.latest_data = null;
    },
    SET_DIALOG_DATA(state, payload) {
      state.dialog_data = payload;
    },
    CLEAR_DIALOG_DATA(state) {
      state.dialog_data = null;
    },
    SET_TEXT_DIALOG_TYPE(state, payload) {
      state.text_dialog_type = payload;
    },
    SET_TEXT_DIALOG_DATA(state, payload) {
      state.text_dialog_data = payload;
    },
    CLEAR_TEXT_DIALOG_DATA(state) {
      state.text_dialog_data = null;
    },
    SET_DIALOG_VISIBILITY(state, payload) {
      state.is_display_dialog = payload;
    },
    SET_TEXT_DIALOG_VISIBILITY(state, payload) {
      state.is_display_text_dialog = payload;
    },
    SET_DASHBOARD_DIALOG_VISIBILITY(state, payload) {
      state.is_display_dashboard_dialog = payload;
    },
  },
};
