import Helpers from "@/helpers";

export default [
  {
    path: "/",
    beforeEnter: (to, from, next) => {
      Helpers.isTokenExist() ? next() : next("login");
    },
    component: () =>
      import(
        /* webpackChunkName: "navbar-layout" */ "@/layout/NavbarLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "*",
    name: "page-not-found",
    component: () =>
      import(/* webpackChunkName: "page-not-found" */ "@/views/NotFound.vue"),
  },
];
