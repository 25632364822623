import HttpRequest from "../HttpRequest";
import Helpers from "../../helpers";

class DataApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetLatestData(params) {
    const query_string = Helpers.generateQueryString(params);
    const request_url = "/data/latest" + query_string;
    const data = await this.get(request_url);
    return data;
  }
}

export default DataApiProvider;
