<template>
  <v-app>
    <router-view />
    <Loading />
    <TextDialog />
    <MachineDialog />
    <DashboardDialog />
    <ChangePasswordDialog />
    <ErrorDialog />
  </v-app>
</template>

<script>
import { logEvent } from "firebase/analytics";

import Loading from "@/components/common/Loading";
import TextDialog from "@/components/common/dialog/TextDialog";
import MachineDialog from "@/components/common/dialog/MachineDialog";
import DashboardDialog from "@/components/common/dialog/DashboardDialog";
import ChangePasswordDialog from "@/components/common/dialog/ChangePasswordDialog";
import ErrorDialog from "@/components/common/dialog/ErrorDialog";

export default {
  name: "App",
  components: {
    Loading,
    TextDialog,
    MachineDialog,
    ErrorDialog,
    DashboardDialog,
    ChangePasswordDialog,
  },
  mounted() {
    logEvent(this.$analytics, "ecs_web_visit");
  },
};
</script>

<style>
.light {
  font-weight: 300;
}

.medium {
  font-weight: 600 !important;
}

.disabled-cursor {
  cursor: not-allowed !important;
}

.gradient {
  background: linear-gradient(135deg, #419a90 40%, #3a3a77 80%);
}

.air_dryer {
  background: linear-gradient(180deg, #b71c1c 30%, #01579b 70%);
  background-size: 180% 180%;
  animation: gradient 5s ease infinite;
}

.cooling_tower {
  background: linear-gradient(180deg, #ffa726 30%, #d32f2f 70%);
  background-size: 180% 180%;
  animation: gradient 5s ease infinite;
}

.chiller {
  background: linear-gradient(
    180deg,
    #01579b 20%,
    #03a9f4 35%,
    #ffa726 65%,
    #d32f2f 80%
  );
  background-size: 140% 140%;
  animation: gradient 5s ease infinite;
}

.exchanger {
  background: linear-gradient(180deg, #03a9f4 30%, #01579b 70%);
  background-size: 180% 180%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}

.expanded-chip {
  width: 100%;
  max-width: 128px !important;
  justify-content: center;
}

.v-btn__content {
  width: 100%;
  white-space: normal;
}

.v-card__text,
.v-list-item__subtitle {
  color: #2c3e50 !important;
}

.col-5-items {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}
</style>
