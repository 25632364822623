import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);
// Import : Modules
import data from "./modules/data";
import site from "./modules/site";
import profile from "./modules/profile";

// Setup : HTTP Request
import AuthApiProvider from "@/services/providers/AuthApiProvider";
const auth_api_provider = new AuthApiProvider();

// Setup : VuexPersistence
const vuexLocal = new VuexPersistence({
  key: "ecs_storage",
  storage: window.localStorage,
  reducer: (state) => ({ access_token: state.access_token }),
});

export default new Vuex.Store({
  state: {
    is_fetched: true,
    access_token: null,
    is_error_auth: false,
    is_open_error_dialog: false,
    error_msg: null,
  },
  actions: {
    async requestCreateAccessToken({ commit }, params) {
      commit("SET_FETCHING");
      commit("CLEAR_AUTH_STATUS");
      try {
        const response = await auth_api_provider.requestCreateAccessToken(
          params
        );
        commit("SET_ACCESS_TOKEN", response.accessToken);
        window.location.replace("/");
      } catch (error) {
        commit("SET_AUTH_STATUS", error);
      }
      commit("SET_FETCHED");
    },
  },
  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.access_token = payload;
    },
    CLEAR_ACCESS_TOKEN(state) {
      state.access_token = null;
    },
    CLEAR_AUTH_STATUS(state) {
      state.is_error_auth = false;
    },
    SET_AUTH_STATUS(state, payload) {
      state.is_error_auth = true;
      state.error_msg = payload;
    },
    SET_FETCHING(state) {
      state.is_fetched = false;
    },
    SET_FETCHED(state) {
      state.is_fetched = true;
    },
    DISPLAY_ERROR_DIALOG(state, payload) {
      state.is_open_error_dialog = true;
      state.error_msg = payload;
    },
    HIDE_ERROR_DIALOG(state) {
      state.is_open_error_dialog = false;
    },
  },
  modules: { site, data, profile },
  plugins: [vuexLocal.plugin],
});
