import dayjs from "dayjs";
import store from "@/store";

import UNIT_LIST from "@/helpers/config/unit_list";
import DESC_LIST from "@/helpers/config/unit_desc_list";

export default {
  isTokenExist: function () {
    const token = store.state.access_token;
    return token && token != "";
  },
  isCurrentDate: function (value) {
    if (!value) return "";

    const format = "DDMMYYYY";
    const alarm_date = dayjs(value).format(format);
    const current_date = dayjs(new Date()).format(format);

    return alarm_date === current_date;
  },
  isDcostDisconnect: function (date, value, unit) {
    if (!value) return "";

    const dcost_date = dayjs(date);
    const current_date = dayjs(new Date());
    const diff = current_date.diff(dcost_date, unit);

    return diff > value;
  },
  toThousandSeparator(value, decimal) {
    return (value * 1).toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  dateFormatter: function (value, formatter) {
    if (!value) return "";

    if (formatter) {
      switch (formatter) {
        case "iso":
          return dayjs(value).toISOString();

        default:
          return dayjs(value).format(formatter);
      }
    } else {
      return dayjs(value).format("DD MMM");
    }
  },
  valueFormatter: function (value, pid_type, machine) {
    if (!value) return "N/A";

    if (value.value !== "-") {
      switch (value.data) {
        case "ISTAT":
          switch (+value.value) {
            case 0:
            case 16:
            case 144:
            case 208:
            case 560:
            case 688:
            case -32080:
              return "OFF";

            case 1:
              return "ON";

            case 566:
            case 1590:
            case 1718:
            case 1722:
            case -31050:
            case -32074:
              return "LOAD";

            case 562:
            case 570:
            case 1594:
            case -31046:
            case -32070:
              return "UNLOAD";

            default:
              return "ISTAT-" + (+value.value).toFixed(0);
          }
        case "IPHSA":
          if (machine == "chiller") {
            console.log(value.value);
            const calc_percent = (value.value / 368) * 100;
            return this.toThousandSeparator(calc_percent, 2) + "%";
          } else {
            return this.toThousandSeparator(value.value, 2) + " " + value.unit;
          }
        case "ALI01":
          if (machine == "chiller") {
            switch (+value.value) {
              case 0:
                return "Normal";

              default:
                return "Alarm";
            }
          } else {
            switch (+value.value) {
              case 0:
                return "NORMAL";

              case 1:
                return "ALARM";

              default:
                return "ALI01-" + (+value.value).toFixed(0);
            }
          }
        case "ALI02":
          if (machine == "air_dryer") {
            switch (+value.value) {
              case 0:
                return "CLOSE";
              case 1:
                return "OPEN";
              default:
                return "ALI02-" + (+value.value).toFixed(0);
            }
          } else {
            switch (+value.value) {
              case 0:
                return "STOP";
              case 1:
                return "START";
              default:
                return "ALI02-" + (+value.value).toFixed(0);
            }
          }
        case "ALI03":
          if (machine == "chiller") {
            switch (+value.value) {
              case 0:
                return "Manual";
              case 1:
                return "Auto";
              default:
                return "ALI03-" + (+value.value).toFixed(0);
            }
          } else if (machine == "cooling_tower") {
            switch (+value.value) {
              case 0:
                return "NORMAL";
              case 1:
                return "LOW ALARM";
              default:
                return "ALI03-" + (+value.value).toFixed(0);
            }
          } else {
            switch (+value.value) {
              case 0:
                return "MANUAL";
              case 1:
                return "AUTO";
              default:
                return "ALI03-" + (+value.value).toFixed(0);
            }
          }
        case "ALI04":
          if (machine == "chiller") {
            switch (+value.value) {
              case 0:
                return "CLOSE";
              case 1:
                return "OPEN";
              default:
                return "ALI04-" + (+value.value).toFixed(0);
            }
          } else if (machine == "cooling_tower") {
            switch (+value.value) {
              case 0:
                return "HIGH ALARM";
              case 1:
                return "NORMAL";
              default:
                return "ALI04-" + (+value.value).toFixed(0);
            }
          } else {
            switch (+value.value) {
              case 0:
                return "UNLOAD";
              case 1:
                return "LOAD";
              default:
                return "ALI04-" + (+value.value).toFixed(0);
            }
          }
        case "ALI05":
          if (machine == "cooling_tower") {
            switch (+value.value) {
              case 0:
                return "NORMAL";
              case 1:
                return "LOW ALARM";
              default:
                return "ALI05-" + (+value.value).toFixed(0);
            }
          } else {
            switch (+value.value) {
              case 0:
                return "Remote";
              case 1:
                return "Local";
              default:
                return "ALI05-" + (+value.value).toFixed(0);
            }
          }
        case "ALI06":
          if (machine == "chiller") {
            switch (+value.value) {
              case 0:
                return "Close";
              case 1:
                return "Open";
              default:
                return "ALI06-" + (+value.value).toFixed(0);
            }
          } else {
            switch (+value.value) {
              case 0:
                return "No Flow";
              case 1:
                return "Flow";
              default:
                return "ALI06-" + (+value.value).toFixed(0);
            }
          }
        case "ALI07":
          switch (+value.value) {
            case 0:
              return "AUTO";
            case 1:
              return "MANUAL";
            default:
              return "ALI07-" + (+value.value).toFixed(0);
          }
        case "ALI08":
          switch (+value.value) {
            case 0:
              return "No Flow";

            default:
              return "Flow";
          }
        case "MODEO":
          if (machine == "air_comp") {
            switch (+value.value) {
              case 1:
                return "BAND-1";
              case 2:
                return "BAND-2";
              default:
                return "MODEO-" + (+value.value).toFixed(0);
            }
          } else if (machine == "pump") {
            switch (+value.value) {
              case 0:
                return "Dif. Pressure";
              case 1:
                return "Temperature";
              default:
                return "MODEO-" + (+value.value).toFixed(0);
            }
          } else if (machine == "cooling_tower") {
            switch (+value.value) {
              case 0:
                return "Return Temp.";
              case 1:
                return "Wet Bulb";
              default:
                return "MODEO-" + (+value.value).toFixed(0);
            }
          } else {
            return value.value;
          }
        case "PRESU":
          // Chill display "PRESU" as psi by * 14.5038
          if (pid_type == "chill") {
            return this.toThousandSeparator(value.value * 14.5038, 2) + " psi";
          } else {
            return this.toThousandSeparator(value.value, 2) + " " + value.unit;
          }
        case "FLORT":
          return (
            this.toThousandSeparator(value.value * 60, 2) + " " + value.unit
          );
        case "FLOCO":
        case "RUNNT":
        case "MAINT":
        case "MAIN1":
        case "MAIN2":
        case "MAIN3":
        case "COM1R":
        case "COM2R":
        case "COM3R":
        case "REFTH":
        case "ENETO":
          return this.toThousandSeparator(value.value, 0) + " " + value.unit;
        case "ENEIM":
          return this.toThousandSeparator(value.value, 3) + " " + value.unit;
        default:
          return this.toThousandSeparator(value.value, 2) + " " + value.unit;
      }
    }
    return value.value + " " + value.unit;
  },
  getDateFormatter: function (value) {
    if (!value) return "";
    if (this.isCurrentDate(value)) {
      return "Today " + this.dateFormatter(value, "HH:mm");
    } else {
      return this.dateFormatter(value, "DD MMM YYYY HH:mm");
    }
  },
  getLatestDataFormatter: function (latest_data, sensor_list) {
    if (latest_data) {
      const result = latest_data.map((data) => {
        // 1. Match Data with Local Config
        const config_sensor = sensor_list.find(
          (sensor) => sensor.id === data.deviceId
        );
        // 2. Mapped Data Value with (1.)
        let mapped_values = [];
        for (const value of config_sensor.values) {
          const prevent_undefined_value =
            data[value] === undefined ? "-" : data[value];

          const value_format = {
            data: value,
            value: prevent_undefined_value,
            unit: this.getSensorUnit(value),
          };
          mapped_values.push(value_format);
        }

        // 3. Return Object for Map function
        return {
          id: data.deviceId,
          code: config_sensor.device_id,
          name: config_sensor.title,
          values: mapped_values,
          data_at: this.getDateFormatter(data.lastDataAt),
          dcost_at: data.lastNormalDcostAt,
        };
      });
      return result;
    }
    return;
  },
  getMachineDataFormatter: function (machine_type, dialog_data) {
    if (dialog_data) {
      let mapped_values = [];
      for (const key of Object.keys(dialog_data)) {
        if (
          key != "deviceId" &&
          key != "lastDataAt" &&
          key != "DCOST" &&
          key != "lastNormalDcostAt"
        ) {
          const value_format = {
            data: key,
            desc: this.getMachineDescription(machine_type, key),
            value: dialog_data[key],
            unit: this.getSensorUnit(key),
            lastDataAt: dialog_data.lastDataAt ?? "-",
          };
          mapped_values.push(value_format);
        }
      }

      return {
        id: dialog_data.deviceId,
        values: mapped_values,
        data_at: this.getDateFormatter(dialog_data.lastDataAt),
        dcost_at: this.getDateFormatter(dialog_data.lastNormalDcostAt),
      };
    }
    return;
  },
  getSensorUnit: function (value) {
    if (!value) return "";
    return UNIT_LIST[value] ? UNIT_LIST[value] : "";
  },
  getMachineDescription: function (machine_type, value) {
    if (!machine_type) return "N/A";
    if (DESC_LIST[machine_type][value]) {
      return DESC_LIST[machine_type][value]
        ? DESC_LIST[machine_type][value]
        : "N/A";
    }
  },
  getMachineParams: function (machine_type) {
    if (!machine_type) return;
    const machine_object = DESC_LIST[machine_type];
    const machine_params = Object.keys(machine_object);
    return machine_params.join(",");
  },
  getSensorFromMappingData: function (mapping_data, sensor_code) {
    return mapping_data.find((data) => data.code === sensor_code);
  },
  generateRequestLatestDataParams: function (sensor_list) {
    let sensor_params = [];
    const request_params = sensor_list.reduce(
      (accum, sensor, index) => {
        // Field: Device IDs
        const delimiter = index !== sensor_list.length - 1 ? "," : "";
        accum.deviceIds += sensor.id + delimiter;

        // Field: Params
        for (const value of sensor.values) {
          if (!sensor_params.includes(value)) {
            sensor_params.push(value);
          }
        }
        accum.params = sensor_params.join(",");

        // Result
        return accum;
      },
      { deviceIds: "", params: "" }
    );
    return request_params;
  },
  generateQueryString: function (filter_object) {
    // Check is Filter objet != null or undefined
    if (filter_object) {
      const filter_keys = Object.keys(filter_object);
      // Check is Filter objet is not empty
      if (filter_keys.length > 0) {
        const query_string =
          "?" +
          filter_keys.map((key) => key + "=" + filter_object[key]).join("&");
        return query_string;
      }
    }
    return "";
  },
};
