<template>
  <v-card
    height="100%"
    class="pa-2"
    outlined
    :class="getClassName()"
    :color="avatar_color ? avatar_color : 'grey'"
    style="border: none; text-align: center"
    @click="displayDialog()"
  >
    <!-- AVATAR -->
    <v-card-text>
      <v-list-item-avatar size="58" color="white" class="ma-0">
        <v-icon :color="avatar_color ? avatar_color : 'grey'" large>
          {{ icon }}
        </v-icon>
      </v-list-item-avatar>
    </v-card-text>
    <!-- CONTENT -->
    <v-card-text>
      <v-list-item-title class="white--text">
        <b>{{ name }} </b>
      </v-list-item-title>
      <v-list-item-subtitle class="white--text medium">
        <span v-if="data_at">{{ data_at }}</span>
        <span v-else> - </span>
      </v-list-item-subtitle>
    </v-card-text>
    <!-- VALUES -->
    <v-card-text class="pa-1">
      <v-list-item v-for="(value, index) in values" :key="index" class="px-0">
        <v-list-item-content class="py-1">
          <v-list-item-title>
            <v-chip
              v-if="value.data !== 'ISTAT'"
              color="white"
              class="expanded-chip"
            >
              <b>{{ convertValueUnit(value) }}</b>
            </v-chip>
            <v-chip v-else color="white" class="expanded-chip">
              <b>
                <span v-if="+value.value == 0" class="red--text text--darken-2">
                  OFF
                </span>
                <span
                  v-else-if="+value.value == 1"
                  class="green--text text--darken-1"
                >
                  ON
                </span>
                <span v-else> ISTAT-{{ value.value }} </span>
              </b>
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

import Helpers from "@/helpers";

export default {
  name: "MachineCard",
  props: [
    "id",
    "code",
    "name",
    "values",
    "data_at",
    "icon",
    "avatar_color",
    "from_page",
    "pid_type",
    "machine_type",
  ],
  methods: {
    ...mapActions({
      requestGetDialogData: "requestGetDialogData",
    }),
    convertValueUnit(value) {
      return Helpers.valueFormatter(value, this.pid_type);
    },
    displayDialog() {
      if (this.machine_type) {
        const machine_info = {
          name: this.name,
          code: this.code,
          machine_type: this.machine_type,
        };
        const dialog_params = {
          deviceIds: this.id,
          params: Helpers.getMachineParams(this.machine_type),
        };
        this.requestGetDialogData({ dialog_params, machine_info });
      }
    },
    getClassName() {
      let class_name = [];
      if (!this.machine_type) {
        class_name.push("disabled-cursor");
      }

      if (this.from_page === "pid") {
        switch (this.code) {
          case "C1002HEX0001":
          case "C1002HEX0002":
            class_name.push("exchanger");
            break;

          case "C1002ADR0001":
          case "C1002ADR0002":
          case "C1002ADR0003":
          case "C1002ADR0004":
          case "C1001ADR0001":
          case "C1001ADR0002":
            class_name.push("air_dryer");
            break;

          case "C1002CTW0001":
          case "C1002CTW0002":
          case "C1002CTW0003":
          case "C1002CTW0004":
          case "C1002CTW0005":
            class_name.push("cooling_tower");
            break;
          case "C1002CHI0004":
          case "C1002CHI0005":
          case "C1002CHI0006":
          case "C1002CHI0007":
          case "C1002CHI0008":
          case "C1002CHI0001":
          case "C1002CHI0002":
          case "C1002CHI0003":
            class_name.push("chiller");
            break;
          default:
            class_name.push("");
            break;
        }
      }
      return class_name.join(" ");
    },
  },
};
</script>
