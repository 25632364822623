import HttpRequest from "../HttpRequest";

class SiteApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetSiteList() {
    const request_url = "/sites";
    const data = await this.get(request_url);
    return data;
  }
}

export default SiteApiProvider;
