<template>
  <v-overlay
    id="DashboardDialog"
    v-if="is_display_dashboard_dialog"
    :opacity="0.3"
    :value="is_display_dashboard_dialog"
  >
    <v-dialog v-model="is_display_dashboard_dialog" max-width="820" persistent>
      <v-card v-if="mapping_data">
        <v-card-title class="text-h5 justify-space-between">
          <b> Machine Summary </b>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="data in mapping_data" :key="data.id" cols="4">
              <MachineCard
                v-if="data"
                v-bind="data"
                :icon="getIcon(data)"
                :avatar_color="getColor(data)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import Helpers from "@/helpers";
import UPI_DASHBOARD from "@/helpers/config/upi_dashboard";

import MachineCard from "@/components/common/card/MachineCard";

export default {
  name: "DashboardDialog",
  components: {
    MachineCard,
  },
  data: () => ({
    polling: null,
    sensor_list: UPI_DASHBOARD,
  }),
  computed: {
    ...mapState({
      dialog_data: (state) => state.data.dialog_data,
      is_display_dashboard_dialog: (state) =>
        state.data.is_display_dashboard_dialog,
    }),
    mapping_data: function () {
      return Helpers.getLatestDataFormatter(this.dialog_data, this.sensor_list);
    },
  },
  filters: {
    valueFormatter: function (value, machine_type) {
      if (!value) return "-";
      return Helpers.valueFormatter(value, "air", machine_type);
    },
  },
  methods: {
    ...mapMutations({
      setDashboardDialogVisibility: "SET_DASHBOARD_DIALOG_VISIBILITY",
    }),
    getIcon(data) {
      switch (data.code) {
        case "C1002ACO0001":
        case "C1002ACO0002":
        case "C1002ACO0003":
        case "C1002ACO0004":
        case "C1002ACO0005":
        case "C1002ACO0006":
        case "C1002ACO0007":
          return "mdi-air-conditioner";

        case "C1002ADR0001":
        case "C1002ADR0002":
        case "C1002ADR0003":
        case "C1002ADR0004":
          return "mdi-radiator";

        case "C1002CTW0001":
        case "C1002CTW0002":
        case "C1002CTW0003":
        case "C1002CTW0004":
        case "C1002CTW0005":
          return "mdi-coolant-temperature";

        case "C1002PUM0001":
        case "C1002PUM0002":
        case "C1002PUM0003":
        case "C1002PUM0004":
        case "C1002PUM0005":
        case "C1002PUM0006":
        case "C1002PUM0007":
        case "C1002PUM0008":
        case "C1002PUM0009":
        case "C1002PUM0010":
          return "mdi-car-turbocharger";

        case "C1002CHI0001":
        case "C1002CHI0002":
        case "C1002CHI0003":
        case "C1002CHI0004":
        case "C1002CHI0005":
        case "C1002CHI0006":
        case "C1002CHI0007":
        case "C1002CHI0008":
          return "mdi-snowflake-variant";

        default:
          return "mdi-memory";
      }
    },
    getColor(data) {
      switch (data.code) {
        case "C1002ACO0001":
        case "C1002ACO0002":
        case "C1002ACO0003":
        case "C1002ACO0004":
        case "C1002ACO0005":
        case "C1002ACO0006":
        case "C1002ACO0007":
          return "green";

        case "C1002ADR0001":
        case "C1002ADR0002":
        case "C1002ADR0003":
        case "C1002ADR0004":
          return "red darken-2";

        case "C1002CTW0001":
        case "C1002CTW0002":
        case "C1002CTW0003":
        case "C1002CTW0004":
        case "C1002CTW0005":
          return "teal";

        case "C1002PUM0010":
        case "C1002PUM0007":
        case "C1002PUM0008":
        case "C1002PUM0009":
        case "C1002PUM0004":
        case "C1002PUM0005":
        case "C1002PUM0006":
        case "C1002PUM0001":
        case "C1002PUM0002":
        case "C1002PUM0003":
          return "light-blue darken-4";

        case "C1002CHI0001":
        case "C1002CHI0002":
        case "C1002CHI0003":
        case "C1002CHI0004":
        case "C1002CHI0005":
        case "C1002CHI0006":
        case "C1002CHI0007":
        case "C1002CHI0008":
          return "indigo darken-2";

        default:
          return "red";
      }
    },
    closeDialog() {
      this.setDashboardDialogVisibility(false);
    },
  },
};
</script>

<style></style>
