<template>
  <v-overlay id="TextDialog" :opacity="0.3" :value="is_display_text_dialog">
    <v-dialog v-model="is_display_text_dialog" max-width="820" persistent>
      <v-card v-if="text_dialog_data">
        <v-card-title class="text-h5 justify-space-between">
          <b>{{ this.text_dialog_data.name }} </b>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
        </v-card-title>
        <!-- VALUES -->
        <v-card-text>
          <v-row>
            <v-col
              v-for="device_data in text_dialog_data.data"
              :key="device_data.id"
              cols="6"
            >
              <v-list-item class="grow px-0">
                <!-- CONTENT -->
                <v-list-item-content>
                  <!-- TITLE + TIME -->
                  <v-list-item-title class="font-weight-regular">
                    <span>
                      {{ device_data.code }} | {{ device_data.values[0].data }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    :class="getTextColor(device_data.data_at)"
                  >
                    <span> Last Data At : {{ device_data.data_at }} </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <!-- VALUE + UNIT -->
                <v-list-item-action>
                  <span class="font-weight-bold">
                    {{
                      device_data.values[0] | valueFormatter(text_dialog_type)
                    }}
                  </span>
                </v-list-item-action>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import Helpers from "@/helpers";

import { mapMutations, mapState } from "vuex";

export default {
  name: "TextDialog",
  filters: {
    valueFormatter: function (value, type) {
      if (!value) return "-";
      switch (type) {
        case "chill":
          return Helpers.valueFormatter(value, "chill");

        default:
          return Helpers.valueFormatter(value);
      }
    },
  },
  computed: {
    ...mapState({
      text_dialog_type: (state) => state.data.text_dialog_type,
      text_dialog_data: (state) => state.data.text_dialog_data,
      is_display_text_dialog: (state) => state.data.is_display_text_dialog,
    }),
  },
  methods: {
    ...mapMutations({
      setTextDialogVisibility: "SET_TEXT_DIALOG_VISIBILITY",
    }),

    getTextColor(data_at) {
      const result = data_at.includes("Today");
      if (result) {
        return "primary--text";
      } else {
        return "red--text";
      }
    },
    closeDialog() {
      this.setTextDialogVisibility(false);
    },
  },
};
</script>

<style></style>
