<template>
  <v-overlay id="MachineDialog" :opacity="0.3" :value="is_display_dialog">
    <v-dialog v-model="is_display_dialog" max-width="820" persistent>
      <v-card v-if="mapping_data">
        <v-card-title class="text-h5 justify-space-between">
          <b
            >{{ dialog_data.machine.name }} |
            {{ dialog_data.machine.code }}
          </b>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- CONTENT -->
          <v-list-item-title class="medium">
            <v-chip v-if="is_disconnected" class="my-2" label color="amber">
              <span>
                <v-icon left> mdi-wifi-off </v-icon>
                Disconnected | {{ mapping_data.dcost_at }}
              </span>
            </v-chip>
            <v-chip v-else class="my-2" label>
              <v-icon left color="black"> mdi-clock-time-nine-outline </v-icon>
              <span v-if="mapping_data.data_at">{{
                mapping_data.data_at
              }}</span>
              <span v-else> - </span>
            </v-chip>
          </v-list-item-title>
        </v-card-text>
        <!-- VALUES -->
        <v-card-text>
          <v-row>
            <v-col
              v-for="value in mapping_data.values"
              :key="value.data"
              cols="6"
            >
              <v-list-item class="grow px-0">
                <!-- CONTENT -->
                <v-list-item-content>
                  <!-- TITLE + TIME -->
                  <v-list-item-title
                    class="font-weight-regular"
                    style="white-space: normal"
                  >
                    <span> {{ value.desc }} </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text text--darken-2">
                    <span> {{ value.data }} </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <!-- VALUE + UNIT -->
                <v-list-item-action>
                  <span class="font-weight-bold">
                    {{
                      value | valueFormatter(dialog_data.machine.machine_type)
                    }}
                  </span>
                </v-list-item-action>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import Helpers from "@/helpers";

export default {
  name: "MachineDialog",
  filters: {
    valueFormatter: function (value, machine_type) {
      if (!value) return "-";
      return Helpers.valueFormatter(value, "air", machine_type);
    },
  },
  computed: {
    ...mapState({
      dialog_data: (state) => state.data.dialog_data,
      is_display_dialog: (state) => state.data.is_display_dialog,
    }),
    mapping_data: function () {
      if (
        this.dialog_data &&
        this.dialog_data.data &&
        this.dialog_data.machine
      ) {
        return Helpers.getMachineDataFormatter(
          this.dialog_data.machine.machine_type,
          this.dialog_data.data
        );
      }
      return null;
    },
    is_disconnected: function () {
      if (this.dialog_data) {
        const status = Helpers.isDcostDisconnect(
          this.dialog_data.data.lastNormalDcostAt,
          15,
          "minute"
        );
        return status;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations({
      setDialogVisibility: "SET_DIALOG_VISIBILITY",
    }),
    closeDialog() {
      this.setDialogVisibility(false);
    },
  },
};
</script>

<style></style>
