<template>
  <v-overlay id="ErrorDialog" :opacity="0.3" :value="is_open_error_dialog">
    <v-dialog v-model="is_open_error_dialog" persistent max-width="320">
      <v-card class="text-center mx-auto" flat rounded="xl" color="nav_color">
        <v-card-title
          class="red--text text--darken-1 font-weight-bold justify-center"
        >
          Error Occurred
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text class="mt-4">
          <span class="font-weight-medium">
            {{ error_msg ? error_msg : "-" }}</span
          >
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="justify-center pb-4">
          <v-btn
            rounded
            depressed
            color="primary"
            @click.prevent="hideErrorDialog()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "ErrorDialog",
  computed: {
    ...mapState({
      error_msg: (state) => state.error_msg,
      is_open_error_dialog: (state) => state.is_open_error_dialog,
    }),
  },
  methods: {
    ...mapMutations({
      hideErrorDialog: "HIDE_ERROR_DIALOG",
    }),
  },
};
</script>

<style></style>
