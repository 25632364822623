import HttpRequest from "../HttpRequest";

class ProfileApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestUpdatePassword(params) {
    // {
    //     "oldPassword":"Test1234",
    //     "newPassword":"Test1234"
    // }
    const request_url = "/profile/password";
    const data = await this.update(request_url, params);

    return data;
  }

  async requestLogout() {
    const request_url = "/profile/logout";
    const data = await this.create(request_url);

    return data;
  }
}

export default ProfileApiProvider;
