import ProfileApiProvider from "@/services/providers/ProfileApiProvider";

const profile_api_provider = new ProfileApiProvider();
profile_api_provider.setAuthorizationHeadersFromLocal();

export default {
  state: {
    is_show_change_password_form: false,
  },
  actions: {
    async requestUpdatePassword({ commit }, params) {
      commit("SET_FETCHING");
      try {
        await profile_api_provider.requestUpdatePassword(params);
        commit("HIDE_PASSWORD_FORM");
      } catch (error) {
        commit("HIDE_PASSWORD_FORM");
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_FETCHED");
    },
    async requestLogout({ commit }) {
      commit("SET_FETCHING");
      try {
        await profile_api_provider.requestLogout();
        commit("CLEAR_ACCESS_TOKEN");
        window.location.replace("/login");
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_FETCHED");
    },
  },
  mutations: {
    SHOW_PASSWORD_FORM(state) {
      state.is_show_change_password_form = true;
    },
    HIDE_PASSWORD_FORM(state) {
      state.is_show_change_password_form = false;
    },
  },
};
