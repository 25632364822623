<template>
  <v-overlay
    id="ChangePasswordDialog"
    :opacity="0.3"
    :value="is_show_change_password_form"
  >
    <v-dialog v-model="is_show_change_password_form" max-width="420" persistent>
      <v-card>
        <v-card-title class="text-h5 justify-space-between">
          <b> Change Password </b>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
        </v-card-title>
        <!-- OLD + NEW PASSWORD -->
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                rounded
                outlined
                clearable
                hide-details
                color="primary"
                background-color="white"
                label="Old Password"
                value="Old Password"
                v-model="old_pwd"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                dense
                rounded
                outlined
                clearable
                hide-details
                color="primary"
                background-color="white"
                label="New Password"
                value="New Password"
                v-model="new_pwd"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- ACTIONS -->
        <v-card-actions class="justify-center pb-4">
          <v-btn
            rounded
            elevation="0"
            color="primary"
            :disabled="!is_enable_change_pwd_btn"
            @click.prevent="changePasswordBtnClicked"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "ChangePasswordDialog",
  data: () => ({
    old_pwd: null,
    new_pwd: null,
  }),
  computed: {
    ...mapState({
      is_show_change_password_form: (state) =>
        state.profile.is_show_change_password_form,
    }),
    is_enable_change_pwd_btn: function () {
      if (this.old_pwd && this.new_pwd) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations({
      hidePasswordForm: "HIDE_PASSWORD_FORM",
    }),
    ...mapActions({
      requestUpdatePassword: "requestUpdatePassword",
    }),
    changePasswordBtnClicked() {
      this.requestUpdatePassword({
        oldPassword: this.old_pwd,
        newPassword: this.new_pwd,
      });
    },
    closeDialog() {
      this.hidePasswordForm();
    },
  },
};
</script>

<style></style>
