import SiteApiProvider from "@/services/providers/SiteApiProvider";

const site_api_provider = new SiteApiProvider();
site_api_provider.setAuthorizationHeadersFromLocal();

export default {
  state: {
    site_list: [],
    selected_site_id: null,
  },
  getters: {
    selected_site(state) {
      if (state.selected_site_id && state.site_list.length > 0) {
        let matched = state.site_list.find(
          (task) => task._id == state.selected_site_id
        );
        return matched;
      }
      return null;
    },
  },
  actions: {
    async requestGetSiteList({ commit }) {
      commit("SET_FETCHING");
      try {
        const response = await site_api_provider.requestGetSiteList();
        commit("SET_SITES", response.sites);
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_FETCHED");
    },
    selectedSiteById({ commit }, site_id) {
      let site_name;
      switch (site_id) {
        case "61c2bf7b1925f922dc565dc9":
          site_name = "EXCELLA";
          break;
        case "61c2bfed34d5ab5ad825d2b0":
          site_name = "UPI";
          break;
        default:
          site_name = "";
          break;
      }
      commit("SET_SITES", site_name);
    },
  },
  mutations: {
    SET_SITES(state, payload) {
      state.site_list = payload;
    },
    SET_SELECTED_SITE_ID(state, payload) {
      state.selected_site_id = payload;
    },
  },
};
